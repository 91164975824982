<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
  useFieldValues,
  useField,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldEmits>()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
  handleBlur,
} = useField(props, emit)

const values = useFieldValues(props.node, props.parentPath)

const { cast, castPlugin } = useFieldCast(props.node)

// Initialize calculated field, after setting default value
const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue)
registerData()

useFieldCache(props.node, parentValue, props.preserveCache)

// Clear value on options change
watch(values, () => {
  const hasOption = values.value.some(({ value }) => value === modelValue.value)
  if (!hasOption) {
    modelValue.value = cast(props.node.defaultValue)
  }
})
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <FormKit
      :id="id"
      v-model="modelValue"
      :type="type"
      :name="node.name"
      :label="label"
      :options="values"
      label-by="key"
      track-by="value"
      outer-class="w-full"
      label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
      :plugins="[castPlugin]"
      :disabled="disabled"
      :min="min"
      :max="max"
      @input="handleChange"
      @blur="handleBlur"
    >
      <template v-if="node.prefix" #prefix>
        <span
          class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
        >
          {{ node.prefix }}
        </span>
      </template>
      <template v-if="node.suffix" #suffix>
        <span
          class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
        >
          {{ node.suffix }}
        </span>
      </template>
    </FormKit>
  </ManagerSharedFieldBase>
</template>
